import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p><strong parentName="p">{`“Filthy 50”`}</strong></p>
    <p>{`50 Box Jumps (24/20)`}</p>
    <p>{`50 Jumping Pullups`}</p>
    <p>{`50 KBS (35`}{`#`}{`)`}</p>
    <p>{`50 Walking Lunges`}</p>
    <p>{`50 Push Press (45/33)`}</p>
    <p>{`50 Back Extensions`}</p>
    <p>{`50 Wall Balls (20/14)`}</p>
    <p>{`50 Burpees`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`No Parking on Main from 6am-12 today so you may need to park on
Washington and come in the back entrance.  `}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      